<template lang="pug">
  .profile_img_box
    .profile_img_wrap
      img.profile_img(:src="imgUrl" alt="プロフィールの画像" loading="lazy")
      div(v-if="isAccount || this.$route.name === 'admin_edit'")
        .edit_area
          label.camera(for="fileInput" @change="onFileChange")
            input#fileInput(type="file" accept="image/*" ref="uploadImg")
      ModalItem.edit_modal(
        v-if="modal"
        v-scroll-lock="modal"
        @close="cancel"
        @submit="upload"
        submit="更新"
      )
        p.ttl こちらの画像をアップロードしますか？
          |
          br
          |
          small.attention ※既存の画像は削除されます
        .preview_wrap
          img.upload_img(v-show="updateImage" :src="updateImage.fileUrl" alt="")
</template>

<script>
import { mapActions } from 'vuex'
import {
  findTargetImage,
  uploadImage,
  deleteImage,
} from '@/apis/firebaseStorage'
import ImageUtil from '@/utils/ImageUtil.js'
import ModalItem from './ModalItem.vue'

export default {
  name: 'ProfileImg',
  props: {
    email: String,
    profileImgName: String,
    profileImgUrl: String,
    id: {
      type: Number,
      required: true,
      default: 0,
    },
    isAccount: Boolean,
  },
  data() {
    return {
      isUploading: false,
      updateImage: { fileUrl: '', fileName: '', blob: null },
      modal: false,
      imgUrl: '/img/loading.gif',
      accountInfo: {},
      fileName: '',
    }
  },
  components: {
    ModalItem,
  },
  created() {
    this.fileName = this.profileImgName
    this.imgUrl = this.profileImgUrl
    if (typeof this.imgUrl == 'undefined' || !this.imgUrl) {
      if (typeof this.fileName != 'undefined') {
        findTargetImage(this.profileImgName)
          .then((imgUrl) => {
            this.imgUrl = imgUrl
          })
          .catch(() => {
            this.imgUrl = '/img/noImg.png'
          })
      } else {
        this.imgUrl = '/img/noImg.png'
      }
    }
  },
  methods: {
    ...mapActions('authModule', ['updateAccount']),
    ...mapActions('usersModule', ['updateUser', 'setData']),
    async onFileChange(e) {
      this.isUploading = true
      const file = (e.target.files || e.dataTransfer.files)[0]
      if (!file) {
        return
      }
      try {
        // 圧縮した画像を取得
        const compFile = await ImageUtil.getCompressImageFileAsync(file)

        // 画像情報の設定
        this.updateImage.blob = compFile
        this.updateImage.fileUrl = await ImageUtil.getDataUrlFromFile(compFile)
        const pos = file.name.lastIndexOf('.')
        let ext = pos === -1 ? '' : file.name.slice(pos + 1)
        this.updateImage.fileName = this.id + '.' + ext
      } catch (err) {
        // エラーメッセージ等を表示
      } finally {
        this.isUploading = false
      }
      this.openModal()
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    async upload() {
      const updateFileName = this.updateImage.fileName
      if (typeof this.fileName != 'undefined' || this.fileName) {
        await deleteImage(this.fileName)
      }
      const uploadTask = uploadImage(this.updateImage.blob, updateFileName)
      uploadTask.then(() => {
        findTargetImage(updateFileName)
          .then((imgUrl) => {
            this.imgUrl = imgUrl
            const updateData = {
              profileImgName: updateFileName,
              profileImgUrl: imgUrl,
            }
            if (this.isAccount) {
              this.updateAccount(updateData)
            } else {
              this.updateUser({ email: this.email, updateData: updateData })
            }
          })
          .catch(() => {
            this.imgUrl = '/img/noImg.png'
          })
      })
      this.$refs.uploadImg.value = null
      this.fileName = updateFileName
      this.closeModal()
    },
    cancel() {
      this.$refs.uploadImg.value = null
      this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.profile_img_box {
  max-width: 220px;
  max-height: 220px;
  margin: 0 auto;
  .profile_img_wrap {
    position: relative;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    padding-bottom: 100%;
    border-radius: 50%;
    position: relative;
    border-radius: 50%;
    border: 2px solid #88cabe;
    background-color: #f1f2f3;
    z-index: 1;
    img.profile_img {
      position: absolute;
      width: 100%;
      max-width: 105% !important;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      height: auto;
    }
    .edit_area {
      transition: opacity 0.2s ease-in-out;
      background-color: rgba(32, 33, 36, 0.6);
      bottom: 0;
      height: 20%;
      left: 0;
      position: absolute;
      right: 0;
      cursor: pointer;
      .camera {
        display: block;
        background-image: url(../static/bg/camera.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        height: 100%;
        opacity: 0.8;
        cursor: pointer;
        input {
          display: none;
        }
      }
    }
    .edit_modal {
      .ttl {
        text-align: center;
        font-size: 14px;
        padding: 5px 0;
        .attention {
          padding: 3px 0 0;
          font-size: 12px;
          color: #888;
        }
      }
      .preview_wrap {
        position: relative;
        display: block;
        margin: 10px auto 15px;
        overflow: hidden;
        width: 220px;
        height: 220px;
        position: relative;
        border-radius: 50%;
        border: 3px solid rgb(136 202 190);
        background-color: #f1f2f3;
        z-index: 1;
        img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          height: auto;
        }
      }
    }
  }
}
</style>
